/* 
==========================
Box-sizing- Border-box
===========================
*/
*,
*:before,
*:after {
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

/* 
==========================
fonts
===========================
*/
@font-face {
  font-family: "Circular Std";
  src: local("Circular Std Medium"), local("CircularStd-Medium"),
    url("./assets/fonts/CircularStd-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Circular Std";
  src: local("Circular Std Black"), local("CircularStd-Black"),
    url("./assets/fonts/CircularStd-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: "Circular Std";
  src: local("Circular Std Bold"), local("CircularStd-Bold"),
    url("./assets/fonts/CircularStd-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "Open Sans";
  src: url("./assets/fonts/OpenSans-Light.ttf");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Open Sans";
  src: url("./assets/fonts/OpenSans-Regular.ttf");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Open Sans";
  src: url("./assets/fonts/OpenSans-SemiBold.ttf");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "Open Sans";
  src: url("./assets/fonts/OpenSans-Bold.ttf");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Circular Std Book";
  src: url("./assets/fonts/CircularStd-Book.ttf");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Circular Std Book";
  src: url("./assets/fonts/CircularStd-BookItalic.ttf");
  font-weight: normal;
  font-style: italic;
}
/* 
==========================
Variables
===========================
*/
html {
  font-size: 100%;
  scroll-behavior: smooth;
} /*16px*/
body {
  margin: 0;
  height: 100%;
}
input[type="checkbox"] {
  height: 21px;
  width: 21px;
  margin-top: 1px;
}
input[type="radio"],
input[type="checkbox"] {
  position: relative;
  -webkit-appearance: none;
  height: 1.25em;
  width: 1.25em;
  margin-bottom: -0.25em;
  margin-right: 5px;
  vertical-align: top;
}
[type="text"],
[type="password"],
[type="search"],
[type="email"],
[type="url"],
[type="number"],
[type="tel"],
textarea,
select,
input[type="radio"],
input[type="checkbox"] {
  -webkit-transition: border-color 0.2s;
  -o-transition: border-color 0.2s;
  -moz-transition: border-color 0.2s;
  transition: border-color 0.2s;
}
[type="text"],
[type="password"],
[type="search"],
[type="email"],
[type="url"],
[type="number"],
[type="tel"],
textarea,
select,
input[type="radio"],
input[type="checkbox"],
.input-prefix,
.input-suffix {
  border: 1px solid #aaa;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  background-color: #fff;
  color: #484848;
}
input,
button,
select,
textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
label,
input,
textarea,
select,
input[type="radio"],
input[type="checkbox"],
.input-prefix,
.input-suffix {
  line-height: normal;
}
input[type="checkbox"],
input[type="radio"] {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0;
}

select {
  font-size: 100%;
}
input input,
textarea,
select,
button,
a {
  font-family: inherit;
  font-size: 100%;
}
:root {
  --clr-black: hsl(0, 0%, 13%);
  --clr-white: hsl(0, 0%, 100%);
  /* alert colors */
  --clr-red-light: hsl(355, 70%, 91%);
  --clr-red-dark: hsl(355, 61%, 32%);
  --clr-green-light: hsl(153, 31%, 86%);
  --clr-green-dark: hsl(152, 69%, 19%);
  /* primary colors */
  /* lighter shades of primary colors */
  --clr-primary-100: hsl(149, 47%, 89%);
  --clr-primary-200: hsl(148, 47%, 78%);
  --clr-primary-300: hsl(149, 48%, 66%);
  --clr-primary-400: hsl(148, 48%, 55%);
  /* main primary color */
  --clr-primary-500: hsl(148, 61%, 44%);
  /* dark shades of primary colors */
  --clr-primary-600: hsl(148, 61%, 35%);
  --clr-primary-700: hsl(149, 61%, 26%);
  --clr-primary-800: hsl(148, 60%, 18%);
  --clr-primary-900: hsl(149, 60%, 9%);
  /* grey colors */
  /* grey used for paragraphs */
  --clr-grey-100: hsl(210, 17%, 98%);
  --clr-grey-200: hsl(210, 16%, 93%);
  --clr-grey-300: hsl(210, 14%, 89%);
  --clr-grey-400: hsl(210, 14%, 83%);
  --clr-grey-500: hsl(210, 11%, 71%);
  /* darkest grey used for headings */
  --clr-grey-600: hsl(208, 7%, 46%);
  --clr-grey-700: hsl(210, 9%, 31%);
  --clr-grey-800: hsl(210, 10%, 23%);
  --clr-grey-900: hsl(210, 11%, 15%);
  /* review color */
  --clr-review-blue: hsl(200, 100%, 35%);
  /* error color */
  --clr-error-text: hsl(354, 70%, 54%);
  --clr-error-background: hsl(356, 52%, 94%);
  --clr-error-border: hsl(354, 82%, 43%);
  /* fonts */
  --heading-font: "Circular Std", sans-serif;
  --body-font: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  --body-font-2: "Circular Std Book", sans-serif;
  --small-text: 0.7em;
  /* rest of the variables */
  --background-color: var(--clr-grey-100);
  --text-color: var(--clr-grey-900);
  --border-radius: 0.25rem;
  --letter-spacing: 0.063rem;
  --transition: all 0.3s linear;
  --input-transition: -ms-transform 0.15s
      cubic-bezier(0.455, 0.03, 0.515, 0.955) 0s,
    -webkit-transform 0.15s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0s,
    transform 0.15s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0s;
  --btn-transition: box-shadow 0.2s ease 0s, -ms-transform 0.1s ease 0s,
    -webkit-transform 0.1s ease 0s, transform 0.1s ease 0s;
  -webkit-tap-highlight-color: transparent;
  --max-width: 1170px;
  --fixed-width: 600px;
  /* box shadows */
  --shadow-1: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  --shadow-2: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
  --shadow-3: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
  --shadow-4: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
    0 10px 10px -5px rgba(0, 0, 0, 0.04);
  --box-shadow-1: var(--clr-grey-800) 0px 0px 0px 2px inset;
}
/* 
==========================
Global Styles
===========================
*/

html {
  scroll-behavior: smooth;
  -webkit-text-size-adjust: 100%;
}

body {
  background: var(--background-color);
  font-family: var(--body-font);
  font-weight: 400;
  line-height: 1.43;
  font-size: 0.875rem;
  color: var(--text-color);
  line-break: strict;
}

p {
  margin-bottom: 1.5rem;
  max-width: 40em;
}

h1,
h2,
h3,
h4,
h5 {
  margin: 0;
  margin-bottom: 1.38rem;
  font-family: var(--heading-font);
  font-weight: 500;
  text-transform: none;
  line-height: 1.1;
  text-rendering: optimizelegibility;
}

h1 {
  margin-top: 0;
  font-size: 2.5rem;
}
h1:focus {
  outline: none !important;
}
h2 {
  font-size: 2rem;
}
h2:focus {
  outline: none !important;
}
h3 {
  font-size: 1.5rem;
}

h4 {
  font-size: 1.25rem;
}

h5 {
  font-size: 0.875rem;
}
@media screen and (min-width: 800px) {
  h1 {
    margin-top: 0;
    font-size: 3.052rem;
  }

  h2 {
    font-size: 2.441rem;
  }

  h3 {
    font-size: 1.953rem;
  }

  h4 {
    font-size: 1.563rem;
  }

  h5 {
    font-size: 1rem;
  }
  body {
    font-size: 1rem;
  }
  h1,
  h2,
  h3,
  h4,
  h5 {
    line-height: 1;
  }
}

small,
.text-small {
  font-size: var(--small-text);
}
a {
  text-decoration: none;
}
a,
a:hover,
a:focus {
  color: inherit;
  text-decoration: none;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: list-item;
  text-align: -webkit-match-parent;
}
form {
  margin: 0;
  padding: 0;
  text-align: left;
}
/* global classes */
/* images */
.img {
  width: 100%;
  display: block;
  -o-object-fit: cover;
  object-fit: cover;
}
/* buttons */
.btn {
  cursor: pointer;
  color: var(--clr-white);
  background: var(--clr-primary-500);
  border: transparent;
  border-radius: var(--border-radius);
  letter-spacing: var(--letter-spacing);
  text-transform: capitalize;
  padding: 0.75rem 0.75rem 0.75rem 1rem;
  box-shadow: var(--shadow-1);
  transition: var(--transition);
  display: inline-block;
}
.btn:hover {
  background: var(--clr-primary-700);
  box-shadow: var(--shadow-3);
}
.btn-hipster {
  color: var(--clr-primary-500);
  background: var(--clr-primary-200);
}
.btn-hipster:hover {
  color: var(--clr-primary-200);
  background: var(--clr-primary-700);
}
/* form-btns and other block btns */
.btn-block {
  width: 100%;
}
/* alerts */
.alert {
  padding: 0.375rem 0.75rem;
  margin-bottom: 1rem;
  border-color: transparent;
  border-radius: var(--border-radius);
  text-transform: capitalize;
}
.alert-danger {
  color: var(--clr-red-dark);
  background: var(--clr-red-light);
}
.alert-success {
  color: var(--clr-green-dark);
  background: var(--clr-green-light);
}
/* form */
.form {
  width: 90vw;
  max-width: var(--fixed-width);
  background: var(--clr-white);
  border-radius: var(--border-radius);
  box-shadow: var(--shadow-2);
  padding: 2rem 2.5rem;
  margin: 3rem auto;
}
.form-label {
  display: block;
  font-size: var(--small-text);
  margin-bottom: 0.5rem;
  text-transform: capitalize;
  letter-spacing: var(--letter-spacing);
}
.form-input,
.form-textarea {
  width: 100%;
  padding: 0.375rem 0.75rem;
  border-radius: var(--border-radius);
  background: var(--background-color);
  border: 1px solid var(--clr-grey-300);
  text-transform: capitalize;
  outline: none;
}
.form-control {
  padding-bottom: 1.5rem;
}
.form-textarea {
  height: 7rem;
}
::-webkit-input-placeholder {
  font-family: inherit;
  color: var(--clr-grey-400);
}
::-moz-placeholder {
  font-family: inherit;
  color: var(--clr-grey-400);
}
:-ms-input-placeholder {
  font-family: inherit;
  color: var(--clr-grey-400);
}
::-ms-input-placeholder {
  font-family: inherit;
  color: var(--clr-grey-400);
}
::placeholder {
  font-family: inherit;
  color: var(--clr-grey-400);
}
.form-alert {
  color: var(--clr-red-dark);
  letter-spacing: var(--letter-spacing);
  text-transform: capitalize;
}
/* loading */
/* @-webkit-keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}
@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}
.loading {
  width: 6rem;
  height: 6rem;
  border: 0.313rem solid var(--clr-grey-400);
  border-radius: 50%;
  border-top-color: var(--clr-primary-500);
  -webkit-animation: spinner 0.6s linear infinite;
  animation: spinner 0.6s linear infinite;
}
.loading {
  margin: 0 auto;
} */
/* title */
/* .title {
  text-align: center;
} */
/* .title h2 {
 margin-bottom: 2rem;
} */
/* .title-underline {
  background: var(--clr-primary-500);
  width: 7rem;
  height: 0.25rem;
  margin: 0 auto;
  margin-top: -1rem;
} */

/* section-center */

.section-center {
  width: 90vw;
  margin: 0 auto;
  max-width: (var(--max-width));
}
@media screen and (min-width: 992px) {
  .section-center {
    width: 95vw;
  }
}

/* nav-fixed */
.navbar-sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  background: var(--clr-white);
  z-index: 2;
}

@media screen and (min-width: 800px) {
  .navbar-sticky {
    box-shadow: var(--shadow-2);
  }
}
/* section */
.section {
  padding: 5rem 0;
}
.section-center {
  width: 90vw;
  margin: 0 auto;
  max-width: var(--max-width);
}
/* no scroll for body element when modal-open */
.modal-open {
  overflow: hidden;
}
.modal-filter-tab-open {
  overflow: hidden;
}
/* no scoll for when modal turns into dropdown */
@media screen and (min-width: 744px) {
  .modal-open-submenu {
    overflow: scroll;
  }
}
.countdown-section {
  margin-top: -5rem;
}
